import { Injectable } from '@angular/core';
import { ApiService } from 'src/app/api-service/api.service';
import { API_ENDPOINTS_V2 } from 'src/assets/data-variable/api-constant-v2';

@Injectable({
  providedIn: 'root'
})
export class BenefitsService {

  constructor(private apiService: ApiService) { }

  async getBenefitHeader(planId) {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitHeader}?planId=${planId}&isMandatory=1`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getOptionalBenefitHeader(planId) {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitHeader}?planId=${planId}&isMandatory=0`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  
  async createBenefitHeader(params) {
    try {
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitHeader}`,params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async updateBenefitHeader(benefitHeaderId,params) {
    try {
      let apiResponse = await this.apiService.putApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitHeader}/${benefitHeaderId}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async createBulkBenefitHeader(params) {
    try {
      let apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS_V2.benefit}`, params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getBenefitHeaderValue(benefitHeaderId) {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitValue}/${benefitHeaderId}`, {});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async deleteBenefitHeader(benefitHeaderId,planId) {
    try {
      const apiResponse = await this.apiService.deleteApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitHeader}?benefitHeaderId=${benefitHeaderId}&planId=${planId}`);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getBenefitCopayHeader(planId,benefitLink) {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitCopay}?planId=${planId}&benefitLink=${benefitLink}`,{});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async updateBenefitValue(benefitValueId,params) {
    try {
      const apiResponse = await this.apiService.putApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitValue}/${benefitValueId}`,params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async insertBenefitValue(params) {
    try {
      const apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitValue}`,params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async deleteBenefitHeaderValue(benefitHeaderValueId,planId) {
    try {
      const apiResponse = await this.apiService.deleteApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitValue}?benefitValueId=${benefitHeaderValueId}&planId=${planId}`);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async updateBenefitFactor(benefitHeaderId ,params) {
    try {
      const apiResponse = await this.apiService.putApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitFactor}/${benefitHeaderId }`,params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async insertBenefitCopayHeaderAndLink(params) {
    try {
      const apiResponse = await this.apiService.postApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitHeaderCopay}`,params);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getBenefitCopay(planId,benefitLink) {
    try {
      const apiResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.benefitCopay}?planId=${planId}&benefitLink=${benefitLink}`,{});
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }

  async getCurrentBenefits(planId){
    try {
      const apiResponse = await this.apiService.getFileApiCall(`${API_ENDPOINTS_V2.benefit}/${API_ENDPOINTS_V2.currentBenefits}?planId=${planId}`);
      return apiResponse;
    } catch (exception) {
      throw exception
    }
  }
}
