import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from "ngx-toastr";
import { ApiService } from "src/app/api-service/api.service";
import { AuthServiceService } from "src/app/authentication/auth-service/auth-service.service";
import { API_ENDPOINTS } from "src/app/insurer-v1/constant/api.constant";
import { PermissionServiceService } from "src/app/permissions/permission-service.service";
import { SessionService } from "src/app/session-service/session.service";
import { API_ENDPOINTS_DISTRIBUTOR } from "src/assets/data-variable/api-constant";
import { TOKEN } from "src/assets/data-variable/session-variables";
import { routePathForPeakre } from 'src/assets/data-variable/mock-data';

@Component({
  selector: 'app-email-quote',
  templateUrl: './email-quote.component.html',
  styleUrls: ['./email-quote.component.scss']
})
export class EmailQuoteComponent implements OnInit {

  uuid: string;
  riId: string;
  insurerName: string;
  quoteType: string;
  logo: any;
  showAxaLogo = false;
  templateName: any = 'signIn';
  enableNotFound = false;
  loginForm: FormGroup;
  submitted = false;
  show = false;
  insurerTheming: any;
  reinsurerName: any = "";

  captchaCode: string;
  failed_count = 0;
  validateCaptch: boolean = false;
  errorLogin: boolean = false;

  request = "welcome";

  constructor(private activateRoute: ActivatedRoute ,private router: Router ,private spinner: NgxSpinnerService,private permissions:PermissionServiceService, private toaster: ToastrService, private apiService: ApiService,  private sessionService: SessionService, public fb: FormBuilder, ) { }

  async ngOnInit(): Promise<void> {

    this.activateRoute.paramMap.subscribe(params => {
      this.uuid = params.get('uuid');
    });

   
    // Get the query parameters (RiId, insurerName, quoteType)
    this.activateRoute.queryParamMap.subscribe(queryParams => {
      this.insurerName = queryParams.get('insurerName');
      this.quoteType = queryParams.get('quoteType');
    });
    const validInsurers = [
      'staging-test', 
    ];
    
    if (validInsurers.includes(this.insurerName)) {
      this.reinsurerName = routePathForPeakre[this.insurerName]
      
    }

    this.loginForm = this.fb.group({
          email: ["", [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$')]],
          password: ["", [Validators.required]],
          captchaText: [''],
        });
        
        await this.verifyPath();
        const pathName = location.pathname.split("/");
        if(pathName.includes('orientinsurance')){
            this.insurerTheming = 'orientinsurance'
        }else if(pathName.includes('newton') ){
          this.insurerTheming = 'newton';
          this.showAxaLogo = true;
        }else{
            this.insurerTheming = 'default'
        }
        this.sessionService.set('insurerTheming',this.insurerTheming)
      }
    
      get formControls() {
        return this.loginForm.controls;
      }
    
      async verifyPath() {
        try {
          let params = {
            reinsurerCode : this.reinsurerName,
            insurerName: this.insurerName,
          };
          let response =  await this.apiService.postApiCall(`auth/${API_ENDPOINTS.checkInsurerAndReinsurePath}`, params);
          this.logo = response?.response[0]?.logo;
          this.sessionService.set('logo',response?.response[0]?.logo)
          this.sessionService.set("insurerURL", this.insurerName);
          this.sessionService.set("reinsurerName", this.reinsurerName);
        } catch (er) {
          if (er.response.data.statusCode == 404) {
            this.enableNotFound = true;
          }
        }
      }
    
      async login() {
        try {
          this.spinner.show();
          this.submitted = true;
          let captchaValid=false;
          if(this.validateCaptch)
          {
          captchaValid=await this.apiService.validateCaptcha(this.captchaCode,this.loginForm);
          if(!captchaValid)
          {
            this.captchaCode =await this.apiService.generateCaptcha(5); // Generate a new captcha
            this.toaster.error("Invalid captcha. Please try again.");
            return
          }
          }
          if (!this.loginForm.valid) {
            return;
          }
    
          this.loginForm.value["insurerURL"] = this.insurerName;
          this.loginForm.value["reInsurerCode"] = this.reinsurerName;
          const cipherText=this.apiService.encryptUsingAES256(JSON.stringify(this.loginForm.value));
          let results = await this.apiService.postApiCall(`${API_ENDPOINTS_DISTRIBUTOR.insurerBasedLogin}`, {credentials:cipherText });
          if (results.statusCode==200)
          {
            this.failed_count=0;
          }
          if (!results) {
            this.toaster.error("Something went wrong");
            return;
          }
          const permissionsResponse = await this.apiService.getApiCall(`${API_ENDPOINTS_DISTRIBUTOR.getAllPermissions}/${results.response.role}`, {});
          const permissions=(results?.response?.permissions?.length>0)?JSON.stringify(results?.response?.permissions):null
          this.permissions.userPermissions= permissions||permissionsResponse.response;
          this.sessionService.set('permissions',permissions||JSON.stringify(permissionsResponse.response));
         
          this.sessionService.set(TOKEN, results.response.token);
          this.sessionService.set("role", results.response.role);
          this.sessionService.set("user_id", results.response.userId);
          this.sessionService.set("insurerURL", results.response.insurerURL);
          this.sessionService.set("brokerCompanyId", results.response.broker_company_id);
          this.sessionService.set("broker_CompanyId", results.response.brokerCompanyId);
          this.sessionService.set("reinsurerName", results.response.reInsurerUrl);
    
          if(results.response.insurerShortForm == 'aexa-re'){
            this.sessionService.set("insurerTheming",'newton');
          }
          this.sessionService.set("insurerName", this.insurerName);
          this.sessionService.set("reinsurerShortForm", results.response.insurerShortForm);
          if(results.response.passwordExpiry && results.response.insurerPasswordEnabledStatus) {
            this.sessionService.set("passwordExpiry", results.response.passwordExpiry)
            this.router.navigateByUrl(`/auth/reset-password`);
            return
          };
          if (results.response.role == "distributor") {

            let quoteResponse = await this.getEmailQuoteDetails();
            
            const { statusCode, response } = quoteResponse;
            const { insurerURL } = results?.response;
            
            if (statusCode === 200 && response.length > 0 && this.quoteType === 'emailQuote') {
              const { client_reference_number, quote_master_id } = response[0];
              const url = `${insurerURL}/distributor-v1/work-flow/basic_details?CRN=${client_reference_number}&quoteMasterId=${quote_master_id}&sendVisible=false`;
              this.router.navigateByUrl(url);
            } else {
              const fallbackUrl = `${insurerURL}/distributor-v1/main`;
              this.router.navigateByUrl(fallbackUrl);
            }
            

          } 
    
    
          this.toaster.success("Logged in successfully");
        } catch (error) {
          if (error.response.data["statusCode"] == 404) {
            this.toaster.error(error.response.data.message);
            return;
          }
          else if (error.response.data["statusCode"] == 403) {
            this.failed_count+=1;
            this.errorLogin = true
            if(this.failed_count>=3)
            {
              this.validateCaptch=true;
              this.captchaCode =await this.apiService.generateCaptcha(5);
    
            }
          }
        } finally {
          this.spinner.hide();
        }
      }
    
      async openForgotPasswordTemplates(){
        this.templateName = 'forgotPassword';
      }
      async passwordUpdatedNotifications(event){
        this.templateName = 'signIn';
      }

      async getEmailQuoteDetails(){
          try {
           let emailQuoteResponse = await this.apiService.postApiCall(`${API_ENDPOINTS_DISTRIBUTOR.getEmailQuoteDetails}`, {uuid:this.uuid });
            return emailQuoteResponse;
          } catch (error) {
            throw error;
          }
        }

  }


