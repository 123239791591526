import { Injectable } from "@angular/core";
import axios, { AxiosInstance } from "axios";
import { ProductVersions, ProductVersionsResponse } from "./product-versions.interface";
import { environment } from "src/environments/environment";
import { BaseInterceptor } from "src/app/shared/shared/interceptors/base.interceptor";
import { API_ENDPOINTS_V2 } from "src/assets/data-variable/api-constant-v2";
import { DatePipe } from "@angular/common";
import * as dayjs from 'dayjs';
import * as customParseFormat from 'dayjs/plugin/customParseFormat';
import { dateFormats } from "src/app/shared/shared/date-formats";
dayjs.extend(customParseFormat);
@Injectable({
    providedIn: 'root'
})
export class ProductVersionsService {

    url: string = environment.apiUrl
    private axios: AxiosInstance;

    constructor(private baseInterceptor: BaseInterceptor, private datePipe: DatePipe) {
        this.axios = axios.create({
            baseURL: this.url
        });
        baseInterceptor.interceptor(this.axios);
    }

    async getProductVersions(): Promise<ProductVersionsResponse> {
        try {
            const result = await this.axios.get(`${environment.apiUrl}Insurer/${API_ENDPOINTS_V2.productVersions}`);
            return result.data;
        } catch (error) {
            throw error
        }
    }
    async getActiveProductVersions(effectiveDate?: Date): Promise<ProductVersionsResponse> {
        try {
            let params = {}
            if (effectiveDate) {
                params['effectiveDate'] = effectiveDate
            }
            const result = await this.axios.get(`${environment.apiUrl}Insurer/${API_ENDPOINTS_V2.productVersions}/active`, { params: params });
            return result.data;
        } catch (error) {
            throw error
        }
    }


    async getProductVersionsById(id: number): Promise<ProductVersionsResponse> {
        try {
            const result = await this.axios.get(`${environment.apiUrl}Insurer/${API_ENDPOINTS_V2.productVersions}/${id}`);
            return result.data;
        } catch (error) {
            throw error
        }
    }

    async getDeleteProductVersionById(id: number): Promise<ProductVersionsResponse> {
        try {
            const result = await this.axios.delete(`${environment.apiUrl}Insurer/${API_ENDPOINTS_V2.productVersions}/${id}`);
            return result.data;
        } catch (error) {
            throw error
        }
    }



    async createNewVersion(productVersions: ProductVersions): Promise<ProductVersionsResponse> {
        try {
            const result = await this.axios.post(`${environment.apiUrl}Insurer/${API_ENDPOINTS_V2.productVersions}`, productVersions);
            return result.data;
        } catch (error: any) {
            // Extract the response error message
            const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
            throw new Error(errorMessage); // Re-throw with the extracted message
        }
    }

    async updateProductVersions(productVersions: ProductVersions): Promise<ProductVersionsResponse> {
        try {
            const result = await this.axios.put(`${environment.apiUrl}Insurer/${API_ENDPOINTS_V2.productVersions}`, productVersions);
            return result.data;
        } catch (error: any) {
            // Extract the response error message
            const errorMessage = error.response?.data?.message || error.message || 'An unexpected error occurred';
            throw new Error(errorMessage); // Re-throw with the extracted message
        }
    }



    formatDate(date?: any, yearFirst: boolean = true): string {
        // Default to current date if no date is provided
        const dateToParse = date || dayjs().format("YYYY-MM-DD HH:mm:ss");
    
        // Attempt to parse the date
        const parsedDate = dayjs(dateToParse);
    
        // Validate the date
        if (!parsedDate.isValid()) {
            console.error("Received invalid date:", date);
            throw new Error("Invalid date format");
        }
    
        // Format the date based on the `yearFirst` flag
        const format = yearFirst ? "YYYY-MM-DD HH:mm" : "DD-MM-YYYY HH:mm";
        return parsedDate.format(format);
    }




}
