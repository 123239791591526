import { premiumCalculation } from "./common-premiumroundup-calculation";
import { insuranceHousepremiumCalculation } from "./insurancehouse-premiumroundup-calculation";
import { wataniaPremiumCalculation } from "./watania-premiumroundup-calculation";

export class PremiumService {
    premiumRoundUp(insurer: any, premium,optionalParam = null) {
      let updatedPremium;
      if ((insurer === "newton" || insurer === "takafulemarat" || insurer === "insurancehouse") && optionalParam == 'gwpRoundOff' || (insurer === "takafulemarat" && optionalParam == 'displayRoundOff')) {
        updatedPremium = new insuranceHousepremiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      } else if (((insurer === "watania-takaful"||insurer === "fidelity" || insurer == "orient" || insurer == "allianz-test") &&  optionalParam == "gwpRoundUp") || ((insurer === "watania-takaful") && optionalParam == "gwpRoundOff")) {
        updatedPremium = new wataniaPremiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      }else {
        updatedPremium = new premiumCalculation();
        return updatedPremium.changePremiumRoundUp(premium);
      }
    }
  }