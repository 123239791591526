<div class="d-flex">
<div class="position-relative" role="button"  [class.disabled]="!isEditable()" [style.pointer-events]="isEditable() ? 'auto' : 'none'"
>
    <span class="add-benefits-button px-4 py-1 semi-bold" role="button">Upload Benefits</span>
    <!-- <button type="button" class="download-template px-3 py-1 f-400 ms-3">
      <a href="https://d6qjctszi34tx.cloudfront.net/group-medical/GM-Plan-Premium.xlsx"
          target="_blank" style="text-decoration: none;color:#FA1066">
          <img src="assets/icons/download-regular.png" class="download-icon me-2">Download Benefits Template
      </a>
  </button> -->
    <input type="file" class="file-selector" (change)="readExcel($event)" #fileClass />
</div>
<div class="position-relative" role="button" >
  <span class="export-btn ms-3 px-4 py-1 semi-bold" role="button" (click)="downloadExcel(planId)">Export Benefits</span>
</div>
</div>
<ng-template #errorInExcel >
    <div class="{{insurerTheming}}">
    <div class="modal-header ">
      <h5 class="modal-title bold headtext" id="exampleModalLabel">Error In Benefit Excel</h5>
      <img src="assets/shared/Close.png" alt="close-icon" (click)="cnacelPopup()" class="close-icon" />
    </div>
    <div class="mt-3 mx-4">
    <div class="fs-5 semi-bold">Benefit Header Sheet can only contain rows with either contain copay_dependency_parent_id or copay_dependency_lookup </div>
    <div class="ruleHeading">Error in </div>
    <div *ngFor="let item of excelErrors">
        <div class="mx-3">Position <span class="ruleHeading">{{item.position}} </span>in Benefit Header Sheet</div>
    </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="cancel-buttons medium px-3" data-dismiss="modal" (click)="cnacelPopup()">Cancel</button>
    </div>
  </div>
</ng-template>
